.custom-label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.custom-icon {
  font-size: 15px; /* Adjust size as necessary */
  padding: 5px;
  border-radius: 5px; /* Optional for rounded corners */
  transition: all 0.3s; /* Smooth transition for color and background */
  width: 25px;
  height: 25px;
}

.icon-checked {
  color: white;
}

.icon-unchecked {
  color: #ccc;
  background-color: #efefef;
}

.icon-booked {
  background-color: #337ab7;
}

.icon-unbooked {
  background-color: #ee6310;
}

.custom-input {
  display: none; /* Hide the actual checkbox */
}
