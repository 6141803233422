.box {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.custom-row {
  display: flex;
  gap: 20px;
  flex-direction: row; /* Default to row, override with media query if needed */
}

.custom-column {
  flex: 1;
}

.table-row {
  padding: 5px 10px;
}

.dark-row {
  background-color: black;
  color: #fcfcfc;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
  padding: 10px;
}

@media (max-width: 767px) {
  .custom-row {
    flex-direction: column;
  }
}

.calendar__day-content {
  flex-direction: column;
  font-size: 1.2rem;
}
.calendar__month-content {
  font-size: 2rem;
}
.calendar__week-content {
  font-size: 1.5rem;
}
.disabled-text .calendar__day-content p{
  text-decoration: line-through;
}