.booking-event {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
}
.booking-event::-webkit-scrollbar:horizontal {
  height: 0;
  width: 0;
  display: none;
}


.booking-event h4 {
  display: block;
  background: #ccc;
  color: #333;
  text-transform: uppercase;
  padding: 5px 10px;
}
.booking-event h6{
  display: block;
  background: #ccc;
  color: #333;
  text-transform: uppercase;
  padding: 10px;
  margin: 0px 0px 20px;
  clear: both;
}

.booking-event .highlighted-row{
  background: #ccc;
  color: #333;
  padding: 2.5px 0px;
}

.booking-event .highlighted-row-tr {
  background: #000;
  color: #fff;
  text-transform: uppercase;
  padding: 5px 0px;
}

.booking-event .border-dashed {
  border-bottom: 1px dashed #ccc;
}

.booking-event .highlighted-row h5 {
    margin: 0;
}
